import { useAppDispatch } from 'src/redux/Store';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import GuessInvoiceDisplayer from 'src/components/Elements/GuessInvoiceDisplayer';
import { getInvoiceGuessAction, resetGuessInvoice } from 'src/redux/actions/GuessInvoiceAction';
import { setCurrentOrganisation } from 'src/redux/actions/organisationActions';

const GuessInvoicePage = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [invoiceId, setInvoiceId] = useState<Nullable<number>>(0);

  const getInvoiceId = async (organisation: string) => dispatch(getInvoiceGuessAction(params.get('id') ?? '', organisation));

  const getOrganisation = () => params.get('organisation') || '';

  const getInvoice = async () => {
    const organisation = getOrganisation();
    if (organisation) {
      const id = await getInvoiceId(organisation);
      setInvoiceId(id);
    }
  };

  useEffect(() => {
    dispatch(resetGuessInvoice());
    dispatch(setCurrentOrganisation({
      id: getOrganisation(),
    }));
    getInvoice();
  }, []);

  return (
    <>
      <div style={{ maxWidth: '900px', margin: '0 auto', padding: '0 32px' }}>
        <GuessInvoiceDisplayer invoiceId={invoiceId} />
      </div>
    </>
  );
};

export default GuessInvoicePage;
