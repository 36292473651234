import makeActionCreator from 'src/lib/makeActionCreator';
import { State } from 'src/redux/reducers/RootReducer';
import {
  getEntitiesData,
  postDownloadEntitesData,
  postEmailEntitiesData,
} from 'src/apis/QuerybuilderAPI';
import { ENTITIES, QuerybuilderResult } from 'src/models/QuerybuilderModel';
import { setFormStatus } from 'src/redux/actions/formActions';
import { FORM, FORM_STATUS } from 'src/constants/Form';
import { DownloadOptionsModel, FORMAT } from 'src/models/DownloadModel';
import { Filters, SEARCH_LOGICAL_OPERATORS } from 'src/models/AdvanceSearchModel';
import { SEARCH_OPERATORS } from 'src/constants/SearchOperators';
import { getFields } from 'src/lib/FieldHelper';
import { kebabToSnake } from 'src/lib/QueryBuilderHelper';
import { DownloadOption } from 'src/lib/makeApiRequest';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';
import { normalizeInvoice } from 'src/redux/actions/invoiceActions';
import { normalizePayment } from 'src/redux/actions/paymentActions';

export const SET_COLUMNS = 'SET_COLUMNS';
export const SET_ENTITY = 'SET_ENTITY';
export const SET_FIELDS = 'SET_FIELDS';
export const SET_FILTERS = 'SET_FILTERS';
export const RESET_FILTERS = 'RESET_FILTERS';
export const SET_OPERATOR = 'SET_OPERATOR';
export const SET_ORDERS = 'SET_ORDERS';
export const SET_PAGINATION = 'SET_PAGINATION';
export const SET_OPTIONS = 'SET_OPTIONS';
export const SET_QUERY_BUILDER_RESULT = 'SET_QUERY_BUILDER_RESULT';
export const SET_QUICK_SEARCH = 'SET_QUICK_SEARCH';
export const RESET_QUERY_BUILDER_RESULT = 'RESET_QUERY_BUILDER_RESULT';

export const setColumns = makeActionCreator(SET_COLUMNS);
export const setOptions = makeActionCreator(SET_OPTIONS);
export const resetQueryBuilderResult = makeActionCreator(RESET_QUERY_BUILDER_RESULT);
export const setEntity = makeActionCreator(SET_ENTITY);
export const setFields = makeActionCreator(SET_FIELDS);
export const setFilters = makeActionCreator(SET_FILTERS);
export const resetFilters = makeActionCreator(RESET_FILTERS);
export const setOperator = makeActionCreator(SET_OPERATOR);
export const setOrders = makeActionCreator(SET_ORDERS);
export const setPagination = makeActionCreator(SET_PAGINATION);
export const setQueryBuilderResult = makeActionCreator(SET_QUERY_BUILDER_RESULT);
export const setQuickSearch = makeActionCreator(SET_QUICK_SEARCH);

export const postDownloadEntitesAction: (
  entity: ENTITIES,
  options: DownloadOptionsModel,
) => ThunkedAction<State> = (
  entity: ENTITIES,
  options: DownloadOptionsModel,
) => async (dispatch: any, getState: any) => {
  const organisation = getState().currentOrganisation.id;
  const { querybuilder } = getState();
  const {
    filters, orders,
  } = querybuilder[entity];
  const query = {
    filters,
    orders,
    options,
    fields: (options.format === FORMAT.csv)
      ? getFields(getState(), entity)
      : [],
  };
  if (query.fields.length === 0) {
    Reflect.deleteProperty(query, 'fields');
  }
  if (options.all) {
    Reflect.deleteProperty(query, 'filters');
  }
  const response = await postDownloadEntitesData(organisation, entity, query, options);
  if (response.status !== 200) {
    dispatch(setAlert({
      type: ALERT_TYPE.error,
      code: 'messages.file_not_found',
    }));
  }
};

export const postDownloadSelectedEntitesAction:
(
  entity: ENTITIES,
  ids: number[],
  options: DownloadOptionsModel,
  customFilterField?: string,
  downloadOption?: DownloadOption,
) => ThunkedAction<State> =
  (
    entity: ENTITIES,
    ids: number[],
    options: DownloadOptionsModel,
    customFilterField?: string,
    downloadOption?: DownloadOption,
  ) => async (dispatch: any, getState: any) => {
    const organisation = getState().currentOrganisation.id;
    const { querybuilder } = getState();
    const {
      orders,
    } = querybuilder[entity] ?? [];
    const filters = {
      [SEARCH_LOGICAL_OPERATORS.and]: [
        [`${kebabToSnake(entity)}.${customFilterField ?? 'id'}`, SEARCH_OPERATORS.is_in, ids],
      ],
    } as Filters;
    const query = {
      filters,
      orders,
      fields: (options.format === FORMAT.csv)
        ? getFields(getState(), entity)
        : [],
      options,
    };
    if (query.fields.length === 0) {
      Reflect.deleteProperty(query, 'fields');
    }

    const response = await postDownloadEntitesData(
      organisation,
      entity,
      query,
      options,
      downloadOption,
    );

    if (response.status !== 200) {
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.file_not_found',
      }));
    }
  };

export const postEmailEntitesAction:
(entity: ENTITIES, options: DownloadOptionsModel) => ThunkedAction<State> =
  (entity: ENTITIES, options: DownloadOptionsModel) => async (dispatch: any, getState: any) => {
    const organisation = getState().currentOrganisation.id;
    const { querybuilder } = getState();
    const {
      filters, orders,
    } = querybuilder[entity];
    const query = {
      filters,
      orders,
      fields: (options.format === FORMAT.csv)
        ? getFields(getState(), entity)
        : [],
      options,
    };
    if (query.fields.length === 0) {
      Reflect.deleteProperty(query, 'fields');
    }
    await postEmailEntitiesData(organisation, entity, query, options);
  };

export const postEmailSelectedEntitesAction:
(
  entity: ENTITIES,
  ids: number[],
  options: DownloadOptionsModel,
  customFilterField?: string
) => ThunkedAction<State> =
  (
    entity: ENTITIES,
    ids: number[],
    options: DownloadOptionsModel,
    customFilterField?: string,
  ) => async (dispatch: any, getState: any) => {
    const organisation = getState().currentOrganisation.id;
    const { querybuilder } = getState();
    const {
      orders,
    } = querybuilder[entity];
    const filters = {
      [SEARCH_LOGICAL_OPERATORS.and]: [
        [`${kebabToSnake(entity)}.${customFilterField ?? 'id'}`, SEARCH_OPERATORS.is_in, ids],
      ],
    } as Filters;
    const query = {
      filters,
      orders,
      options,
      fields: (options.format === FORMAT.csv)
        ? getFields(getState(), entity)
        : [],
    };
    if (query.fields.length === 0) {
      Reflect.deleteProperty(query, 'fields');
    }
    await postEmailEntitiesData(organisation, entity, query, options);
  };

const normalizeEntities = (data: any[], entity: ENTITIES) => data.map((value:any) => {
  switch (entity) {
    case ENTITIES.invoices:
      return normalizeInvoice(value);
    case ENTITIES.payments:
      return normalizePayment(value);
    default:
      return value;
  }
});

export const getEntitiesAction:
(entity: ENTITIES) => ThunkedAction<State> =
  (entity: ENTITIES) => async (dispatch: any, getState: any) => {
    dispatch(setFormStatus({ [FORM.query_builder]: FORM_STATUS.processing }));
    const { querybuilder } = getState();
    const organisation = getState().currentOrganisation.id;
    const payload = { ...querybuilder[entity] };
    const response = await getEntitiesData(organisation, entity, payload);
    if (response.success) {
      const { ids, count, result } = response.data;
      dispatch(setQueryBuilderResult({
        entity,
        result: {
          count,
          ids,
          list: normalizeEntities(result, entity),
        } as QuerybuilderResult,
      }));
      dispatch(setFormStatus({ [FORM.query_builder]: FORM_STATUS.success }));
    } else {
      dispatch(setFormStatus({ [FORM.query_builder]: FORM_STATUS.error }));
    }
  };
