import { MODALS } from 'src/models/ModalModel';
import ModalBase from 'src/components/Modals/ModalBase';
import { MODALS_SIZE } from 'src/models/modal';
import MakeUserDonationForm from 'src/components/Forms/MakeUserDonationForm';

const UserDonationModal = () => (
  <ModalBase
    name={MODALS.userDonation}
    size={MODALS_SIZE.small}
  >
    <MakeUserDonationForm />
  </ModalBase>
);

export default UserDonationModal;
