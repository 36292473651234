import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import Button from '@material-ui/core/Button';
import { closeModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/Elements/MenuButton';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { openDialog } from 'src/redux/actions/dialogActions';
import { DIALOGS } from 'src/models/DialogModel';
import { useEffect, useState } from 'react';
import { t } from 'src/lib/language';
import { TextField, Tooltip, Typography } from '@material-ui/core';
import { ProgramItemModel } from 'src/models/ProgramListModel';
import AddProgramForm from 'src/components/Forms/AddProgramForm';
import EditProgramForm from 'src/components/Forms/EditProgramForm';
import { getProgramsAction } from 'src/redux/actions/dataActions';
import { sortBy } from 'lodash';
// eslint-disable-next-line import/no-extraneous-dependencies
import InfiniteScroll from 'react-infinite-scroll-component';

const useStyles = makeStyles((theme) => ({
  header: {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    height: '56px',
  },
  title: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.light,
    fontSize: '18px',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    height: '4vh',
    gap: '10px',
  },
  scroller: {
    overflowY: 'auto',
    height: '597px',
    padding: '8px',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    height: '4vh',
    gap: '10px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
    padding: '0 8px',
  },
  search: {
    height: '6vh',
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },
}));

export enum STATE {
  list = 'list',
  edit = 'edit',
  add = 'add',
}

const ManagePrograms = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getProgramsAction());
  }, []);

  const programs = useSelector((state: Store) => state.data.programs);

  const handleClose = () => {
    dispatch(closeModal({ modal: MODALS.managePrograms }));
  };

  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const [currentProgram, setcurrentProgram] = useState({} as ProgramItemModel);
  const [currentState, setCurrentState] = useState(STATE.list);
  const [currentFilter, setCurrentFilter] = useState('');
  const [page, setPage] = useState(1);
  const [items, setItems] = useState(Object.values(programs).slice(0, 20));
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    setItems(Object.values(programs).slice(0, 20));
  }, [programs]);

  const handleDelete = (e:any, group: ProgramItemModel) => {
    e.preventDefault();
    dispatch(openDialog({ dialog: DIALOGS.deleteProgram, payload: group }));
  };

  const handleEdit = (department: ProgramItemModel) => {
    setcurrentProgram(department);
    setCurrentState(STATE.edit);
  };

  const handleAdd = () => {
    setcurrentProgram({} as ProgramItemModel);
    setCurrentState(STATE.add);
  };

  const handleNext = () => {
    const sorted = sortBy(programs, (program) => program[`name_${lang}`]);
    let result = sorted;
    if (currentFilter) {
      result = sorted.filter(
        (item) => item[`name_${lang}`]
          .toLowerCase()
          .includes(currentFilter.toLowerCase()),
      );
    }
    const newItems = result.slice(page * 20, (page + 1) * 20);
    setItems((prevItems) => [...prevItems, ...newItems]);
    setPage((prevPage) => prevPage + 1);
    setHasMore(result.length > (page + 1) * 20);
  };

  const getTitle = () => {
    switch (currentState) {
      case STATE.add:
        return t(lang, 'menus.add_program');
      case STATE.edit:
        return t(lang, 'menus.edit_program');
      default:
        return t(lang, 'menus.list_program');
    }
  };

  const getStyle = (index: number) => {
    const style = {} as any;
    if (!index) {
      style.borderTop = '1px solid rgba(0, 0, 0, 0.25)';
    }
    if (index % 2) {
      style.backgroundColor = '#eee';
    }
    return style;
  };

  const getItems = () => items.map(
    (program: ProgramItemModel, index: number) => {
      if (currentFilter) {
        if (!program[`name_${lang}`].toLowerCase().includes(currentFilter.toLowerCase())) return null;
      }
      return (
        <div
          className={classes.item}
          key={`Program-${index}`}
          style={getStyle(index)}
        >
          <span><b>{ program[`name_${lang}`] }</b></span>
          <Tooltip
            title={t(lang, 'menus.edit_Program')}
            placement="top-start"
          >
            <span style={{ marginLeft: 'auto' }}>
              <ActionButton
                variant="outlined"
                category={MENU_BUTTON_CATEGORY.action}
                onClick={() => handleEdit(program)}
              >
                <EditIcon />
              </ActionButton>
            </span>
          </Tooltip>
          <Tooltip
            title={t(lang, 'menus.delete_Program')}
            placement="top-start"
          >
            <span style={{ marginLeft: '8px' }}>
              <ActionButton
                variant="outlined"
                category={MENU_BUTTON_CATEGORY.action}
                onClick={(e) => handleDelete(e, program)}
              >
                <DeleteIcon />
              </ActionButton>
            </span>
          </Tooltip>
        </div>
      );
    },
  );

  const displayItems = () => (
    <InfiniteScroll
      dataLength={items.length}
      next={handleNext}
      hasMore={hasMore}
      loader={<Typography>Loading...</Typography>}
      endMessage={<Typography>All items displayed.</Typography>}
      height={590}
    >
      {getItems()}
    </InfiniteScroll>
  );

  const displayEditForm = () => (
    <div className={classes.scroller}>
      <EditProgramForm
        program={currentProgram}
        onClose={() => setCurrentState(STATE.list)}
      />
    </div>
  );

  const displayAddForm = () => (
    <div className={classes.scroller}>
      <AddProgramForm
        onClose={() => setCurrentState(STATE.list)}
      />
    </div>
  );

  return (
    <>
      <div className={classes.header}>
        <div className={classes.row}>
          <div className={classes.title}><b>{getTitle()}</b></div>
          <Button
            style={{ marginLeft: 'auto' }}
            variant="contained"
            color="primary"
            size="small"
            onClick={handleClose}
          >
            Close
          </Button>
        </div>
      </div>
      {currentState === STATE.list && (
      <div className={classes.search}>
        <Tooltip
          title={t(lang, 'menus.add_customfield')}
          placement="top-start"
        >
          <span>
            <ActionButton
              category={MENU_BUTTON_CATEGORY.action}
              onClick={handleAdd}
            >
              <AddIcon />
            </ActionButton>
          </span>
        </Tooltip>
        <TextField
          margin="dense"
          variant="outlined"
          label="Filter"
          onChange={(e) => setCurrentFilter(e.target.value)}
        />
      </div>
      )}
      <div>
        {currentState === STATE.list && displayItems()}
        {currentState === STATE.add && displayAddForm()}
        {currentState === STATE.edit && displayEditForm()}
      </div>
    </>
  );
};

export default ManagePrograms;
