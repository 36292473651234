import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import Button from '@material-ui/core/Button';
import { closeModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/Elements/MenuButton';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { openDialog } from 'src/redux/actions/dialogActions';
import { DIALOGS } from 'src/models/DialogModel';
import { useEffect, useState } from 'react';
import { t } from 'src/lib/language';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { ProductModel } from 'src/models/ProductModel';
import AddProductForm from 'src/components/Forms/AddProductForm';
import EditProductForm from 'src/components/Forms/EditProductForm';
import { getProductListAction } from 'src/redux/actions/productAction';
import { sortBy } from 'lodash';
import { ProductType } from 'src/constants/Products';
// eslint-disable-next-line import/no-extraneous-dependencies
import InfiniteScroll from 'react-infinite-scroll-component';

const useStyles = makeStyles((theme) => ({
  header: {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    height: '56px',
  },
  title: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.light,
    fontSize: '18px',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    height: '4vh',
    gap: '10px',
  },
  scroller: {
    overflowY: 'auto',
    height: '597px',
    padding: '8px',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    height: '4vh',
    gap: '10px',
    padding: '0px 8px',
    borderBottom: '1px solid rgba(0,0,0,0.25)',

  },
  search: {
    height: '6vh',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
}));

export enum STATE {
  list = 'list',
  edit = 'edit',
  add = 'add',
}

const ManageProducts = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getProductListAction());
  }, []);

  const products = useSelector((state: Store) => state.products);

  const handleClose = () => {
    dispatch(closeModal({ modal: MODALS.manageProducts }));
  };

  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const [currentProduct, setcurrentProduct] = useState({} as ProductModel);
  const [currentState, setCurrentState] = useState(STATE.list);
  const [currentFilter, setCurrentFilter] = useState('');
  const [type, setType] = useState('');
  const [page, setPage] = useState(1);
  const [items, setItems] = useState(Object.values(products).slice(0, 20));
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    setItems(Object.values(products).slice(0, 20));
  }, [products]);

  const handleDelete = (e:any, product: ProductModel) => {
    e.preventDefault();
    dispatch(openDialog({ dialog: DIALOGS.deleteProduct, payload: product }));
  };

  const handleEdit = (product: ProductModel) => {
    setcurrentProduct(product);
    setCurrentState(STATE.edit);
  };

  const handleAdd = () => {
    setcurrentProduct({} as ProductModel);
    setCurrentState(STATE.add);
  };

  const handleNext = () => {
    const sorted = sortBy(products, (product) => product[`name_${lang}`]);
    let result = sorted;
    if (type) {
      result = sorted.filter((product) => product.type === type);
    }
    if (currentFilter) {
      result = result.filter(
        (item) => item[`name_${lang}`]
          .toLowerCase()
          .includes(currentFilter.toLowerCase()),
      );
    }
    const newItems = result.slice(page * 20, (page + 1) * 20);
    setItems((prevItems) => [...prevItems, ...newItems]);
    setPage((prevPage) => prevPage + 1);
    setHasMore(result.length > (page + 1) * 20);
  };

  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setType((event.target as HTMLInputElement).value as ProductType);
  };

  const getTitle = () => {
    switch (currentState) {
      case STATE.add:
        return t(lang, 'menus.add_product');
      case STATE.edit:
        return t(lang, 'menus.edit_product');
      case STATE.list:
        return t(lang, 'menus.list_product');
      default:
        return t(lang, 'menus.delete_product');
    }
  };

  const getStyle = (index: number) => {
    const style = {} as any;
    if (!index) {
      style.borderTop = '1px solid rgba(0, 0, 0, 0.25)';
    }
    if (index % 2) {
      style.backgroundColor = '#eee';
    }
    return style;
  };

  const getItems = () => items.map(
    (product: ProductModel, index: number) => {
      if (currentFilter) {
        if (!product[`name_${lang}`].toLowerCase().includes(currentFilter.toLowerCase())) return null;
      }
      return (
        <div
          className={classes.item}
          key={`product-${index}`}
          style={getStyle(index)}
        >
          <span><b>{ product[`name_${lang}`] }</b></span>
          <Tooltip
            title={t(lang, 'menus.edit_product')}
            placement="top-start"
          >
            <span style={{ marginLeft: 'auto' }}>
              <ActionButton
                variant="outlined"
                category={MENU_BUTTON_CATEGORY.action}
                onClick={() => handleEdit(product)}
                name="product-edit"
              >
                <EditIcon />
              </ActionButton>
            </span>
          </Tooltip>
          <Tooltip
            title={t(lang, 'menus.delete_product')}
            placement="top-start"
          >
            <span style={{ marginLeft: '8px' }}>
              <ActionButton
                variant="outlined"
                category={MENU_BUTTON_CATEGORY.action}
                onClick={(e) => handleDelete(e, product)}
                name="product-delete"
              >
                <DeleteIcon />
              </ActionButton>
            </span>
          </Tooltip>
        </div>
      );
    },
  );

  const displayItems = () => (
    <InfiniteScroll
      dataLength={items.length}
      next={handleNext}
      hasMore={hasMore}
      loader={<Typography>Loading...</Typography>}
      endMessage={<Typography>All items displayed.</Typography>}
      height={590}
    >
      {getItems()}
    </InfiniteScroll>
  );

  const displayEditForm = () => (
    <div className={classes.scroller}>
      <EditProductForm
        product={currentProduct}
        onClose={() => setCurrentState(STATE.list)}
      />
    </div>
  );

  const displayAddForm = () => (
    <div className={classes.scroller}>
      <AddProductForm
        onClose={() => setCurrentState(STATE.list)}
      />
    </div>
  );

  return (
    <>
      { currentState === STATE.list && (
        <div className={classes.header}>
          <div className={classes.row}>
            <div className={classes.title}><b>{ getTitle() }</b></div>
            <Button
              style={{ marginLeft: 'auto' }}
              variant="contained"
              color="primary"
              size="small"
              onClick={handleClose}
            >
              {t(lang, 'misc.close')}
            </Button>
          </div>
        </div>
      )}
      { currentState === STATE.list && (
        <>
          <div className={classes.search}>
            <div className={classes.row}>
              <Tooltip
                title={t(lang, 'menus.add_product')}
                placement="top-start"
              >
                <span>
                  <ActionButton
                    category={MENU_BUTTON_CATEGORY.action}
                    onClick={handleAdd}
                  >
                    <AddIcon />
                  </ActionButton>
                </span>
              </Tooltip>
            </div>
            <TextField
              margin="dense"
              variant="outlined"
              label={t(lang, 'forms.products.filter')}
              onChange={(e) => setCurrentFilter(e.target.value)}
            />
          </div>
          <div className={classes.row}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={type}
                onChange={handleTypeChange}
              >
                <FormControlLabel value="" control={<Radio />} label="All" />
                <FormControlLabel value={ProductType.product} control={<Radio />} label="Products" />
                <FormControlLabel value={ProductType.ticket} control={<Radio />} label="Tickets" />
                <FormControlLabel value={ProductType.fee} control={<Radio />} label="Fees" />
                <FormControlLabel value={ProductType.donation} control={<Radio />} label="Donation" />
              </RadioGroup>
            </FormControl>
          </div>
        </>
      )}
      <div>
        { currentState === STATE.list && displayItems() }
        { currentState === STATE.add && displayAddForm() }
        { currentState === STATE.edit && displayEditForm() }
      </div>
    </>
  );
};

export default ManageProducts;
