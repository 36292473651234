import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { t } from 'src/lib/language';
import { useSelector } from 'react-redux';
import { Store } from 'src/redux/Store';
import {
  DELIVERY_TYPE,
  DownloadOptionsModel,
  EXTENSION,
  FORMAT,
  ORIENTATION,
} from 'src/models/DownloadModel';
import { FORM } from 'src/constants/Form';
import FormTextField from 'src/components/Control/FormControls/FormTextField';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';

interface Props {
  options: DownloadOptionsModel;
  opened: boolean;
  handleCancel: () => void,
  handleDownload: () => void,
  handleDownloadAllChange?: (value: boolean) => void,
  onChange: (options: DownloadOptionsModel) => void,
  allowDownload?: boolean;
  allowStatement?: boolean;
  isDownloadSelected?: boolean;
}

const useStyles = makeStyles(() => ({
  row: {
    display: 'flex',
    alignItems: 'baseline',
    gap: '10px',
  },
}));

const BaseDownloadDialog = (props: Props) => {
  const classes = useStyles();
  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const {
    opened,
    handleCancel,
    handleDownload,
    handleDownloadAllChange,
    onChange,
    options,
    allowDownload,
    allowStatement,
    isDownloadSelected,
  } = props;
  const extension = options.format === FORMAT.csv ? EXTENSION.csv : EXTENSION.pdf;

  const [downloadAll, setDownloadAll] = useState(0);

  const onDownloadAllChange = (value: number) => {
    if (handleDownloadAllChange) handleDownloadAllChange(!!value);
    setDownloadAll(value);
  };

  const onContactFieldChange = (value: Value, field: string) => {
    onChange({
      ...options,
      [field]: value,
    });
  };

  const title = isDownloadSelected
    ? t(lang, 'menus.download_selected')
    : t(lang, 'menus.download_result');
  return (
    <Dialog
      aria-labelledby="download-result"
      open={opened}
    >
      <DialogTitle id="download-result">{title}</DialogTitle>
      <DialogContent dividers>
        <div style={{ width: '480px' }}>
          <div className={classes.row}>
            <FormTextField
              form={FORM.download}
              label="File Name"
              name="file"
              onChange={onContactFieldChange}
              required
              value={options.file.split('.')[0]}
            />
            { `.${extension}` }
          </div>
          <FormControl component="fieldset" style={{ marginTop: '8px' }}>
            <FormLabel component="legend">Format</FormLabel>
            <RadioGroup
              row
              aria-label="format"
              name="format"
              value={options.format || FORMAT.pdf}
              onChange={(e) => onContactFieldChange(e.target.value, 'format')}
            >
              <FormControlLabel value={FORMAT.pdf} control={<Radio />} label={FORMAT.pdf} />
              <FormControlLabel value={FORMAT.csv} control={<Radio />} label={FORMAT.csv} />
              {
                allowStatement
                && (
                <FormControlLabel
                  value={FORMAT.statement}
                  control={<Radio />}
                  label={FORMAT.statement}
                />
                )
              }
            </RadioGroup>
          </FormControl>
          <div />
          <FormControl
            component="fieldset"
            style={{ marginTop: '8px' }}
          >
            <FormLabel component="legend">Orientation</FormLabel>
            <RadioGroup
              row
              aria-label="orientation"
              name="orientation"
              value={options.orientation || ORIENTATION.portrait}
              onChange={(e) => onContactFieldChange(e.target.value, 'orientation')}
            >
              <FormControlLabel
                value={ORIENTATION.portrait}
                control={<Radio />}
                label={ORIENTATION.portrait}
              />
              <FormControlLabel
                value={ORIENTATION.landscape}
                control={<Radio />}
                label={ORIENTATION.landscape}
              />
            </RadioGroup>
          </FormControl>
          <div />
          <FormControl
            component="fieldset"
            style={{ marginTop: '8px' }}
          >
            <FormLabel component="legend">Delivery Type</FormLabel>
            <RadioGroup
              row
              aria-label="Delivery Type"
              name="download"
              value={options.delivery || DELIVERY_TYPE.email}
              onChange={(e) => onContactFieldChange(e.target.value, 'delivery')}
            >
              <FormControlLabel
                value={DELIVERY_TYPE.email}
                control={<Radio />}
                label="By Email"
              />
              { allowDownload && (
              <FormControlLabel
                value={DELIVERY_TYPE.download}
                control={<Radio />}
                label="Download"
              />
              )}
            </RadioGroup>
          </FormControl>
          <div />
          <FormControl
            component="fieldset"
            style={{ marginTop: '8px' }}
          >
            <FormLabel component="legend">Download Selected</FormLabel>
            <RadioGroup
              row
              aria-label="Selected"
              name="all"
              value={Number(downloadAll)}
              onChange={(e) => onDownloadAllChange(Number(e.target.value))}
            >
              <FormControlLabel
                value={1}
                control={<Radio />}
                label="All"
              />
              <FormControlLabel
                value={0}
                control={<Radio />}
                label="Only Result"
              />
            </RadioGroup>
          </FormControl>
        </div>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          { t(lang, 'misc.cancel') }
        </Button>
        <Button onClick={handleDownload} color="primary">
          { t(lang, 'misc.download') }
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BaseDownloadDialog;
